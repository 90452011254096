<template>
  <section class="app-main">
    <div class="container is-fluid is-marginless app-content">
      <div class="columns head is-vcentered">
        <div class="column is-offset-3 is-6 has-text-centered">
          <h3 class="title is-size-3 has-text-weight-normal">Documents</h3>
        </div>
        <div class="column is-3 is-hidden-mobile">
          <ol class="breadcrumb">
            <li>
              <a href="/#" class="is-active">Home</a>
            </li>
            <li>
              <span class="active">Documents</span>
            </li>
          </ol>
        </div>
      </div>
      <div class="animated">
        <div class="tile is-ancestor">
          <div class="tile is-parent">
            <article class="tile is-child box">
              <div class="content">
                <p class="title">Guides et manuels utiles</p>
                <div class="container is-fluid">
                  <div class="columns is-multiline">
                    <div class="column is-half">
                      <p>
                        <a href="pdfs/Manuel_membres_La_Louve.pdf" target="_blank">
                          <span class="icon">
                            <i class="fa fa-file"></i>
                          </span> Manuel des membres
                        </a>
                      </p>
                    </div>
                    <div class="column is-half">
                      <p>
                        <a href="pdfs/Manuel_membres_La_Louve.pdf" target="_blank">
                          <span class="icon">
                            <i class="fa fa-file"></i>
                          </span> Pour les coordinateurs
                        </a>
                      </p>
                    </div>
                    <div class="column is-half">
                      <p>
                        <a href="pdfs/Manuel_membres_La_Louve.pdf" target="_blank">
                          <span class="icon">
                            <i class="fa fa-file"></i>
                          </span> Exemple de feuille de présence
                        </a>
                      </p>
                    </div>
                    <div class="column is-half">
                      <p>
                        <a href="pdfs/Manuel_membres_La_Louve.pdf" target="_blank">
                          <span class="icon">
                            <i class="fa fa-file"></i>
                          </span> AG et comité Ordre du Jour
                        </a>
                      </p>
                    </div>
                    <div class="column is-half">
                      <p>
                        <a href="pdfs/Manuel_membres_La_Louve.pdf" target="_blank">
                          <span class="icon">
                            <i class="fa fa-file"></i>
                          </span> Guide de sécurité incendie
                        </a>
                      </p>
                    </div>
                    <div class="column is-half">
                      <p>
                        <a href="pdfs/Manuel_membres_La_Louve.pdf" target="_blank">
                          <span class="icon">
                            <i class="fa fa-file"></i>
                          </span> Statuts de la coopérative
                        </a>
                      </p>
                    </div>
                    <div class="column is-half">
                      <p>
                        <a href="pdfs/Manuel_membres_La_Louve.pdf" target="_blank">
                          <span class="icon">
                            <i class="fa fa-file"></i>
                          </span> Statuts de l’association des amis de La Louve
                        </a>
                      </p>
                    </div>
                    <div class="column is-half">
                      <p>
                        <a href="pdfs/Manuel_membres_La_Louve.pdf" target="_blank">
                          <span class="icon">
                            <i class="fa fa-file"></i>
                          </span> Le Modèle Park Slope
                        </a>
                      </p>
                    </div>
                    <div class="column is-half">
                      <p>
                        <a href="pdfs/Manuel_membres_La_Louve.pdf" target="_blank">
                          <span class="icon">
                            <i class="fa fa-file"></i>
                          </span> Venir au Supermarché
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Documents",
  data: function() {
    return {
      mesinfos: ""
    };
  },
    mounted() {
      window.scrollTo(0,0);
    // this.$http
    //   .get("/api/members/me", {
    //     headers: { Authorization: "Bearer " + localStorage.token }
    //   })
    //   .then(response => {(this.mesinfos = response.data)},
    //   () => {
    //       this.$router.push("logout")
    //     }
    //   )
      //.catch(this.$router.push("logout"))
  },
};
</script>

<style lang="css" scoped>
.is-title {
  text-transform: capitalize;
}

. {
  padding-top: 50px;
  transform: translateZ(0);
}

.subtitle:not(:last-child),
.title:not(:last-child) {
  margin-bottom: 1.5rem;
}

.title {
  color: #363636;
  font-size: 2rem;
  font-weight: 300;
  line-height: 1.125;
}

.level-item .subtitle,
.level-item .title {
  margin-bottom: 0;
}

.subtitle.is-5 {
  font-size: 1.25rem;
}

.subtitle {
  color: #2e2e2e;
  font-weight: 300;
  line-height: 1.25;
}

.subtitle strong {
  color: #363636;
}

.subtitle strong,
.title strong {
  font-weight: 500;
}

.level-item {
  /* -ms-flex-align: center; */
  align-items: center;
  /* display: -ms-flexbox; */
  display: flex;
  /* -ms-flex-preferred-size: auto; */
  flex-basis: auto;
  /* -ms-flex-positive: 0; */
  flex-grow: 0;
  /* -ms-flex-negative: 0; */
  flex-shrink: 0;
  /* -ms-flex-pack: center; */
  justify-content: center;
}

.breadcrumb {
  --separator: "\2044";
  list-style: none;
  /* -ms-flex-align: center; */
  align-items: center;
  /* display: -ms-flexbox; */
  display: flex;
  /* -ms-flex-pack: end; */
  justify-content: flex-end;
}

.level-left .level-item:not(:last-child),
.level-right .level-item:not(:last-child) {
  margin-right: 0.75rem;
}

.is-vertical-center {
  text-align: center;
  padding-top: 10px;
}
</style>
